<template>
  <div>
    <b-alert class="p-1 col-sm-12 col-md-12 col-lg-12 mb-1 custom-alert" variant="warning" show v-if="!showAlert" dismissible>
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 mr-2">
          <feather-icon size="25" icon="AlertTriangleIcon"/>
      </div>
      <div class="flex-grow-1">
        <span class="text-break" style="font-size: 14px; color: gray;">
          Esta compañía no tiene la vista activada para los usuarios externos, según la configuración actual de "mostrar facturas".
        </span>
      </div>
    </div>
    </b-alert>
    <b-alert
      class="custom-alert col-sm-12 col-md-12 col-lg-12 p-1"
      variant="warning"
      show
      v-if="hasSharedTaxID"
      dismissible
    >
      <div class="d-flex align-items-center">
        <div class="flex-shrink-0 mr-2">
          <feather-icon size="25" icon="AlertTriangleIcon" class="text-warning"/>
        </div>
        <div class="flex-grow-1">
          <span class="mb-0 alert-text">
            Esta compañía presenta identificador fiscal compartido. Verificar las facturas con el icono
            <b><feather-icon style="color: #f18805" icon="InfoIcon"/></b>
            de la tabla para más información.
          </span>
        </div>
      </div>
    </b-alert>
    <b-card>
      <table-render
        v-if="!loading.first"
        id="table_billingList"
        :rows="rows"
        :schema="schema"
        :loading="loading.billing"
        :sortByCustom="orderBy"
      >
        <template #statusName="scope">
          <b-badge :variant="scope.rowdata.statusClass">{{scope.rowdata.statusName}}</b-badge>
        </template>
        <!-- Descargar documentos para seller/MKP -->
        <template #documents_url="scope">
          <a class="lnk lnk-primary" @click="onUserClick(scope.rowdata, 'pdf')" target="_blank"> PDF</a>
          |
          <a class="lnk lnk-primary" @click="onUserClick(scope.rowdata, 'xml')" target="_blank"> XML</a>
        </template>
        <!-- Vista para admin -->
        <template #invoiceNum="scope">
          <span>{{scope.rowdata.invoiceNumber}}
            <feather-icon
            v-if="hasSharedTaxID && !hasSameIdAsUrlParams(scope.rowdata.invoiceDetailFile)"
            v-b-tooltip.hover.top
            :title="getCompanyID(scope.rowdata.invoiceDetailFile) ? 'Esta factura pertenece a la compañía ' + getCompanyID(scope.rowdata.invoiceDetailFile) : 'No se pudo verificar compañía a la que pertenece'"
            :icon="getCompanyID(scope.rowdata.invoiceDetailFile) ? 'InfoIcon' : 'AlertTriangleIcon'"/></span>
        </template>
        <template #url="scope">
          <!--  Se ha comentado hasta que se migre totalmente facturacion a Bemmbo (cambiar Duemint por Bemmbo) -->
            <!-- <a class="lnk lnk-primary" :href="scope.rowdata.invoiceUrl" target="_blank">Duemint</a>
            | -->
            <b-button @click="openModalDetailInvoice(scope.rowdata)" variant="link" class="p-0 font-weight-normal">Detalle</b-button>
        </template>
        <!-- Seller/MKP -->
        <template #enviame="scope">
          <b-button v-if="scope.rowdata.invoiceDetailFile && scope.rowdata.invoiceDetailFile.url" @click="downloadInvoice(scope.rowdata)" variant="link" class="p-0 font-weight-normal text-left"><feather-icon icon="FileIcon"/>&nbsp;Detalle envíos</b-button>
          <span v-else> Sin detalles</span>
        </template>
        <!-- Admin -->
        <template #code="scope">
          <b-button @click="openModalUploadInvoice(scope.rowdata)" variant="link" class="p-0 font-weight-normal">Subir archivo</b-button>
          <template v-if="scope.rowdata.invoiceDetailFile && scope.rowdata.invoiceDetailFile.url">
            |
            <b-button @click="downloadInvoice(scope.rowdata)" variant="link" class="p-0 font-weight-normal">Descargar</b-button>
            |
            <b-button @click="confirmDeleteInvoice(scope.rowdata)" variant="link" class="p-0 font-weight-normal">Eliminar</b-button>
          </template>
        </template>
        <template #actionBtn="scope">
          <b-button @click="openModalNotification(scope.rowdata)" variant="link">
            <feather-icon
              v-b-tooltip.hover.top
              title="Envíar notificación por correo"
              icon="MailIcon"/>
          </b-button>
        </template>
      </table-render>
      <pagination
          v-if="!loading.first"
          :pagination="pagination"
          :noDigits="false"
          :showSize="true"
          :disabled="loading.billing"
      />
      <div class="table-render-skeleton" v-show="loading.first">
          <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 7"
          :table-props="{}"
          />
      </div>
      <modal-upload-invoice :company_id="company_id" :dataInvoice="dataInvoice" @getInvoiceData="getInvoiceData"></modal-upload-invoice>
      <modal-detail-invoice :dataInvoice="dataInvoice" :action="onUserClick"></modal-detail-invoice>
      <modal-send-notification :dataInvoice="dataInvoice" :company_id="company_id"></modal-send-notification>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BillingService from '../../billing.service'
import ModalUploadInvoice from './ModalUploadInvoice.vue'
import ModalSendNotification from './ModalSendNotification.vue'
import ModalDetailInvoice from './ModalDetailInvoice.vue'

export default {
  props: ['company_id'],
  components: { ModalUploadInvoice, ModalDetailInvoice, ModalSendNotification },
  data() {
    return {
      dataInvoice: '',
      loading: {
        first: true,
        billing: true
      },
      rows: [],
      schema: [],
      pagination: { page: 1, limit: 10, total: 0, loading: false },
      environment: null,
      billingService: new BillingService(this),
      sortProps: null,
      hasSharedTaxID: false,
      showAlert: true
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      invoiceData: 'getInvoiceBillingDataCompany',
      hasShowInvoice: 'getHasShowInvoice'
    })
  },
  watch: {
    mySession () {
      this.setAllData()
    },
    invoiceData() {
      if (this.invoiceData.sharedTaxID && ['superadmin', 'admin'].includes(this.$session.get('cas_user').role)) this.hasSharedTaxID = true
      this.rows = this.invoiceData.rows.map(invoiceData => {
        return {
          ...invoiceData,
          totalParse: this.$options.filters.currency(invoiceData.totalAmount, 0, '$'),
          statusClass: this.getStatusClass(invoiceData),
          documents_url: this.validateDocumentsUrl(invoiceData)
        }
      })
      this.loading.first = false
      this.loading.billing = false
      this.pagination.page = this.invoiceData.pagination.current_page
      this.pagination.total = this.invoiceData.pagination.total_items
      this['pagination.limit'] = this.invoiceData.pagination.total_pages
      this.$bvToast.hide('bvToastBillingData')
      this.$bvToast.hide('bvToastBilling')
    },
    'pagination.page'() {
      this.getInvoiceData()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getInvoiceData()
    }
  },
  mounted() {
    this.environment = this.billingService.setEnvironment()
    this.setAllData()
    this.showAlert = this.hasShowInvoice
  },
  methods: {
    setAllData() {
      this.setInitialData()
      if (this.mySession.id) {
        this.getInvoiceData()
      }
    },
    setInitialData() {
      this.schema = [
        { label: 'Estado', key: 'statusName', useSlot: true},
        { label: 'Fecha emisión', key: 'issueDate' },
        { label: 'Fecha vencimiento', key: 'dueDate' },
        { label: 'Monto total', key: 'totalParse' }
      ]
      //Vistas solo para Ecommerce/Seller/MKP
      if (['ecommerce', 'seller', 'marketplace'].includes(this.$session.get('cas_user').role)) {
        this.schema.unshift({ label: 'Número', key: 'invoiceNumber', customSort: 'number' }) //OJO pregutar por el sort
        this.schema.push(
          { label: 'Factura', key: 'documents_url', useSlot: true},
          { label: 'Anexo', key: 'enviame', useSlot: true}
        )
      }
      //Vistas solo para adm
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user').role)) {
        this.schema.unshift({ label: 'Número', key: 'invoiceNum', customSort: 'number', useSlot: true })
        this.schema.push(
          { label: 'Factura', key: 'url', useSlot: true},
          { label: 'Detalles', key: 'code', useSlot: true},
          { label: 'Notificar', key: 'actionBtn', class: ['text-center'], useSlot: true }
        )
      }
    },
    openModalUploadInvoice (data) {
      this.dataInvoice = data
      this.$bvModal.show('modal-upload-invoice')
    },
    openModalDetailInvoice(data) {
      this.dataInvoice = data
      this.$bvModal.show('modal-detail-invoice')
    },
    orderBy (sortAs, type) {
      this.sortProps = type ? type === 'desc' ? `-${sortAs}` : sortAs : null
      this.getInvoiceData()
    },
    getInvoiceData() {
      this.loading.billing = true
      const queryParams = {
        page: this.pagination.page,
        size: this.pagination.limit
      }
      const params = {
        company_id: this.company_id
      }
      if (this.sortProps) queryParams.sort = this.sortProps
      this.$store.dispatch('fetchServiceStatusOnError', { name: 'getInvoiceBillingDataCompany', queryParams, params, onError: (err) => this.onErrorGetInvoiceData(err)})
    },
    validateDocumentsUrl(invoiceData) {
      return  {
        pdf: invoiceData.invoicePdfUrl,
        xml: invoiceData.invoiceXmlUrl,
        default: invoiceData.invoiceUrl,
        needsFetch: invoiceData.invoicePdfFileId || invoiceData.invoiceXmlFileId
      }
    },
    async onUserClick(invoiceData, type) {
      const documents = this.validateDocumentsUrl(invoiceData)
      // Configuración para diferentes tipos de archivos
      const typeConfig = {
        pdf: {
          fileId: invoiceData.invoicePdfFileId,
          url: documents.pdf
        },
        xml: {
          fileId: invoiceData.invoiceXmlFileId,
          url: documents.xml
        },
        default: {
          url: documents.default
        }
      }

      if (!typeConfig[type]) {
        return this.$alert(`Tipo de archivo no soportado: ${type}`)
      }

      // Decide la acción en función del tipo y la necesidad de fetch
      const config = typeConfig[type]
      if (documents.needsFetch && config.fileId) {
        await this.$store.dispatch('fetchDownloadInvoice', {
          name: 'getInvoiceBillingDataCompanyById',
          params: { file_id: config.fileId },
          responseType: 'arraybuffer'
        })
      } else {
        return window.open(config.url, '_blank')
      }
    },
    onErrorGetInvoiceData (error) {
      const {subtitle, detail} = this.handlerStandartError(error)
      const notAdmin = (detail === 'La compañía consultada no posee datos de facturación.')
      this.$alert(`${subtitle}<small>${detail}</small>`, notAdmin ? this.redirect : null, 'Error en la pestaña de Facturas')
    },
    downloadInvoice (data) {
      const params = {
        company_id: this.company_id,
        invoice_number: data.invoiceNumber,
        invoice_file_id: data.invoiceDetailFile?.id
      }
      this.$store.dispatch('fetchService', { name: 'downloadInvoiceBillingDataCompany', params, onSuccess: ({data}) => window.open(data.detail_file_url, '_blank')})
    },
    redirect () {
      if (['superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        window.parent.location.href = `${this.environment}/manage-billing-data`
      } else {
        window.parent.location.href = this.environment
      }
    },
    getCompanyID(dataCompany) {
      return dataCompany?.companyId
    },
    hasSameIdAsUrlParams(companyData) {
      return Number(this.getCompanyID(companyData)) === Number(this.company_id)
    },
    openModalNotification (selectBill) {
      this.dataInvoice = selectBill
      this.$bvModal.show('modal-send-notification')
    },
    confirmDeleteInvoice(number) {
      this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.deleteInvoice(number))
    },
    deleteInvoice(data) { //Falta probar este
      const {invoiceNumber, invoiceDetailFile:{id} } = data
      const params = {
        company_id: this.company_id,
        invoice_number: invoiceNumber,
        invoice_file_id: id
      }
      this.$store.dispatch('fetchService', { name: 'deleteInvoiceBillingDataCompany', params, onSuccess: this.onSuccessDeleteInvoice })
    },
    onSuccessDeleteInvoice() {
      this.getInvoiceData()
      this.$success(this.$t('msg-exito-eliminar-elemento'))
    },
    getStatusClass (bill) {
      switch (bill.statusId) {
      case 1: // Pagada
        return 'primary'
      case 2: // Por vencer
        return 'success'
      case 3: // Vencida
        return 'danger'
      case 4: // Anulada
        return 'secondary'
      default:
        return ''
      }
    }
  }
}
</script>

<style lang="scss">

.alert-sharedTaxID {
  padding: 8px;
}

.custom-alert {
  border: 1px solid #f18805;
  color: black;
  font-size: 12px;

  .alert-text {
    font-size: 14px;
    color: gray;
    line-height: 1.5;
  }

  button.close {
    //font-size: 1rem !important;
    color: gray !important;
    padding: 1rem !important;
  }
}
</style>
